import "./sign-in-form.style.scss";

import { useState } from "react";

import { validateEmail } from "../../utils/login/email-validation";

import { ReactComponent as Logo } from "../../assets/Frame 2.svg";
import FormInput from "../form-input/form-input.component";

import { fetchAPI } from "../../apis/sign-in-api";

const defaultFormFields = {
  email: "",
  password: "",
};

const SignInForm = () => {
  const [newformFields, setNewFormFields] = useState(defaultFormFields);
  const [errorMessage, setErrorMessage] = useState("");

  const { email, password } = newformFields;

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setNewFormFields({ ...newformFields, [name]: value });
  };

  const handleAuthenticationAndRedirection = async () => {
    try {
      const data = await fetchAPI({ email: email, password: password });

      if (!data) {
        throw new Error("Incorrect email or password");
      }

      // If authentication is successful, redirect the user to the homepage:
      if (data.statusCode === 201) {
        const userToken = await data.payLoad.token;
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("token", userToken);
        window.location.href = "home";
      }
      // If authentication fails, show an error message to the user
    } catch (err) {
      throw err;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!validateEmail(email)) {
        throw new Error("Invalid email");
      }
      await handleAuthenticationAndRedirection();
      e.target.reset();
    } catch (err) {
      // all the errors are caught and handled here:
      setErrorMessage(err.message);
      setTimeout(() => setErrorMessage(""), 2000);
      console.error(err.message);
    }
  };

  return (
    <div className="sign-in-form-container">
      <Logo className="logo-app" />
      <span className="app-title">My Tally</span>
      <span className="form-title"> Sign-in with email and password</span>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Email"
          type="email"
          placeholder="email-address"
          name="email"
          value={email}
          onChange={onChangeHandler}
          required
        />
        <FormInput
          label="Password"
          type="password"
          placeholder="password"
          name="password"
          value={password}
          onChange={onChangeHandler}
          required
        />
        <span className="error-message-container">{errorMessage}</span>
        <button className="sign-in-button">Sign In</button>
      </form>
    </div>
  );
};

export default SignInForm;

// 192.168.18.217/api/
