import { USER_ROLES } from "./user-roles";

export const getRoleDisplayName = (userType) => {
  switch (userType) {
    case USER_ROLES.ADMIN:
      return "Admin";
    case USER_ROLES.DOOR_TEAM:
      return "Door team";
    case USER_ROLES.PROMOTOR:
      return "Promoter";
    case USER_ROLES.SUB_PROMOTOR:
      return "Sub Promoter";
    case USER_ROLES.BARTENDER:
      return "Bartender";
    case USER_ROLES.DJ:
      return "DJ";
    case USER_ROLES.SECURITY:
      return "Ticket Taker";
    case USER_ROLES.VIP_HOST:
      return "VIP Host";
    case USER_ROLES.COCKTAIL_WAITRESS:
      return "Cocktail Waitress";
    case USER_ROLES.TEMP_PROMOTOR:
      return "Temp Promotor";
    case USER_ROLES.CASHIER:
      return "Cashier";
    default:
      return "";
  }
};
