export const returnTableRows = (tally, key, className) => {
  return (
    <tr className="report-table-data" key={key}>
      <td className={className}>
        {tally.adminPromotorId ? `${key} (Sub)` : key}
      </td>
      <td className={className}> {tally.totalComps} </td>
      <td className={className}> {tally.totalReduce} </td>
      <td className={className}>
        {tally.adminPromotorId && tally.totalPeopleEntered === 0
          ? ""
          : tally.totalPeopleEntered}
      </td>
      <td className={className}>{tally.totalBottleSales}</td>
      <td className={className}> {tally.totalBarTabs} </td>
      <td className={className}> {tally.totalCompBottles} </td>
    </tr>
  );
};
