import axios from "axios";
import { BASE_URL } from "../utils/constants/constants";

export const fetchAPI = async (responseUser) => {
  const responseObject = responseUser;

  try {
    const response = await axios.post(
      `${BASE_URL}/users/signin`,

      responseObject,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response) {
      throw new Error(`Error fetching data: ${response.statusText}`);
    }

    const data = await response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
};
