import { useState, useEffect } from "react";
import { eventDetailsAPI } from "../../apis/event-details-api";
import { fetchGuestListReports } from "../../apis/guest-list-report-api";
import Reports from "../../components/reports/reports.component";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner.component";
import { seperateFieldsForGuestListReports } from "../../utils/guest-list/helpers";
import "./event-reports-route.style.scss";

import { FaDownload } from "react-icons/fa";

import * as ExcelJS from "exceljs";

const EventDetailsPage = () => {
  const eventObject = JSON.parse(sessionStorage.getItem("eventObject"));
  const [reportsData, setReportsData] = useState({});
  const [guestListReportsData, setGuestListReportsData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const token = localStorage.getItem("token");

  const fetchReportsData = async () => {
    try {
      const data = await eventDetailsAPI(eventObject.id, token);
      setReportsData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDataAndGenerateExcel = async () => {
    try {
      const data = await fetchGuestListReports(token, eventObject.id);
      const mergedData = [...data.payLoad.completed, ...data.payLoad.pending];
      const requiredDataForExcel =
        seperateFieldsForGuestListReports(mergedData);

      setGuestListReportsData(requiredDataForExcel);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchReportsData();
    fetchDataAndGenerateExcel();
  }, []);

  const generateExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    styleExcelSheet(worksheet);

    guestListReportsData.forEach((object) => {
      worksheet.addRow([
        object.Name,
        object.Email,
        object.InvitedBy,
        object.Role,
        object.Type,
        object.Code,
        object.Status,
        object.Date,
        object["Ticket Type"],
      ]);
    });

    const excelBuffer = await workbook.xlsx.writeBuffer();
    saveExcelFile(excelBuffer, "Guestlist.xlsx");
  };

  const styleExcelSheet = (worksheet) => {
    const eventTitleRow = worksheet.addRow([guestListReportsData[0].Event]);
    worksheet.mergeCells(`A1:I1`); // merge cells from A1 to I1 for the event name

    const eventTitleCell = eventTitleRow.getCell(1);
    eventTitleCell.alignment = { vertical: "middle", horizontal: "center" };
    eventTitleCell.font = { bold: true, size: 16 };

    //  header row
    const headerRow = worksheet.addRow([
      "Name",
      "Email",
      "InvitedBy",
      "Role",
      "Type",
      "Code",
      "Status",
      "Date",
      "Ticket Type",
    ]);

    // header styling
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "4b0082" },
      };
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
    });
    headerRow.height = "200px";

    // column widths
    worksheet.columns = [
      { width: 25 },
      { width: 30 },
      { width: 20 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 30 },
      { width: 15 },
    ];
  };

  const saveExcelFile = (data, filename) => {
    const blob = new Blob([data], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  // const stringToArrayBuffer = (s) => {
  //   const buf = new ArrayBuffer(s.length);
  //   const view = new Uint8Array(buf);
  //   for (let i = 0; i < s.length; i++) {
  //     view[i] = s.charCodeAt(i) & 0xff;
  //   }
  //   return buf;
  // };

  if (!reportsData) {
    return (
      <div className="event-reports-container">
        <p className="reports-error-message">
          An Unexpected Error Occured while fetching reports data. Please try
          Again!
        </p>
      </div>
    );
  }

  return (
    <div className="event-reports-container">
      <div className="report-logo-container">
        <img
          src={eventObject.imageUrl}
          className="report-logo"
          alt="event-pic"
        ></img>
      </div>

      <div className="report-name-container">
        <h1 className="report-name">Event Name: {eventObject.eventName} </h1>
      </div>

      <div className="download-report-container">
        <span
          className="download-report-link"
          onClick={
            guestListReportsData
              ? generateExcel
              : alert("Something went wrong! Please try again")
          }
        >
          <FaDownload style={{ marginRight: "10px" }} />
          Guest List Report
        </span>
      </div>

      {isLoading ? <LoadingSpinner /> : <Reports reportsData={reportsData} />}
    </div>
  );
};

export default EventDetailsPage;
