export const setBarTabs = (reportData, barTabsMap) => {
  reportData.map((tallyObject) => {
    return barTabsMap.set(
      {
        clientName: tallyObject.clientName,
        promotorName: tallyObject.promotorName,
      },
      tallyObject
    );
  });
};
