export const totalSorting = (dataArray) => {
  dataArray.sort((firstElement, secondElement) => {
    if (firstElement[0] === "Total" && secondElement[0] !== "Total") {
      return 1; // "Total" comes after other values
    } else if (firstElement[0] !== "Total" && secondElement[0] === "Total") {
      return -1; // "Total" comes before other values
    } else {
      return 0; // no sorting required for other values
    }
  });
  return dataArray;
};
