export const mainReportTableHeaders = [
  "Name",
  " Comps",
  "Reduce",
  "Total",
  "Bottle sales",
  "Bar Tabs",
  "Comp Bottles",
];

export const bottleSalesReportHeaders = [
  "Client Name",
  "Booked via (Promotor)",
  "Party Size",
  "Table Number",
  "Minimum",
  "Final Spend",
  "Comments",
];

export const barTabsReportHeaders = [
  "Client Name",
  "Booked via (Promotor)",
  "Party Size",
  "Minimum Tabs",
  "Comments",
];
