export const alphabeticalSorting = (dataArray) => {
  const dataArrayCpy = [...dataArray];

  dataArrayCpy.sort((firstElement, secondElement) => {
    if (dataArray[0][1].adminPromotorId) return;

    const nameFirst = firstElement[0].clientName
      ? firstElement[0].clientName
      : firstElement[0];
    const nameSecond = secondElement[0].clientName
      ? secondElement[0].clientName
      : secondElement[0];

    if (nameFirst < nameSecond) {
      return -1; // nameFirst comes before nameSecond
    } else if (nameFirst > nameSecond) {
      return 1; // nameFirst comes after nameSecond
    } else {
      return 0; // names are equal
    }
  });
  return dataArrayCpy;
};
