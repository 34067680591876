import "./navigation-bar.styles.scss";

import { Fragment } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";

import { ReactComponent as TallyLogo } from "../../assets/Frame 2.svg";

const Navigation = () => {
  const navigate = useNavigate();
  const signOutUser = () => {
    window.localStorage.clear();
    navigate("/");
  };

  return (
    <Fragment>
      <div className="navigation-container">
        <Link className="nav-logo-container" to="/">
          <TallyLogo className="nav-logo" />
        </Link>

        <div className="nav-links-container">
          <div className="nav-link-container">
            <span className="nav-link" onClick={signOutUser}>
              SIGN OUT
            </span>
          </div>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
