import "./table.style.scss";

import { findSubPromotorAndUpdateCount } from "../../utils/reports/tally-counts/promotor-count";

import { selectClassName } from "../../utils/reports/classname";
import { returnTableRows } from "../../utils/reports/reports-table/main-table-rows";

import { USER_AND_ENTRY_TYPES } from "../../utils/user/user-types";
import { SALES_TYPES } from "../../utils/sales/sales-type";

const TableReports = ({ dataArray, tableHeaders }) => {
  return (
    <table>
      <thead>
        <tr className="report-table-header">
          {tableHeaders.map((header) => {
            return <th key={header}> {header}</th>;
          })}
        </tr>
      </thead>

      <tbody>
        {dataArray.map(([key, tally]) => {
          const className = selectClassName(
            tally.promotor ? tally.promotor.userType : tally.userType
          );
          const userType = tally.userType;
          const saleType = dataArray[0][1]["saleType"];

          // promotors main table:
          if (dataArray[0][0] === USER_AND_ENTRY_TYPES["WALK UP"]) {
            findSubPromotorAndUpdateCount(tally, dataArray);
            return returnTableRows(tally, key, className);
          }

          // staff main table:
          else if (
            userType !== USER_AND_ENTRY_TYPES.PROMOTOR &&
            userType !== USER_AND_ENTRY_TYPES.SUB_PROMOTOR &&
            tally.saleType !== SALES_TYPES.BOTTLE_SALES &&
            tally.saleType !== SALES_TYPES.BAR_TABS
          ) {
            return returnTableRows(tally, key, className);
          }

          // bottle sales table:
          else if (saleType === SALES_TYPES.BOTTLE_SALES) {
            return (
              <tr className="report-table-data" key={key}>
                <td className={className}>{tally.name}</td>
                <td className={className}> {tally.promotorName} </td>
                <td className={className}> {tally.partySize} </td>
                <td className={className}> {tally.tableNumber} </td>
                <td className={className}> {tally.minimum} </td>
                <td className={className}> {tally.totalRevenue} </td>
                <td className={className}> {tally.comments} </td>
              </tr>
            );
          }

          // bar tabs table:
          else {
            return (
              <tr className="report-table-data" key={key}>
                <td className={className}>{tally.name}</td>
                <td className={className}> {tally.promotorName} </td>
                <td className={className}> {tally.partySize} </td>
                <td className={className}> {tally.minTabs} </td>
                <td className={className}> {tally.comments} </td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );
};

export default TableReports;
