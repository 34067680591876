import axios from "axios";
import { BASE_URL } from "../utils/constants/constants";

export const eventDetailsAPI = async (id, token) => {
  const responseObject = {
    eventId: id,
  };
  try {
    const response = await axios.post(
      `${BASE_URL}/mytally/eventReportData`,

      responseObject,

      {
        headers: {
          Authorization: `Bearer ${token}`,
          Platform: "web",
          Version: "5.6.1",
        },
      }
    );
    if (!response)
      throw new Error("Something went wrong. Please try again later.");

    const data = await response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
};
