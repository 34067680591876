import "./landing-route.style.scss";
import { Routes, Route } from "react-router-dom";
import HomePage from "../../pages/homepage/home-page";
import EventDetailsPage from "../event-reports/event-reports-route";

const HomeRoute = () => {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path=":event-details" element={<EventDetailsPage />} />
    </Routes>
  );
};

export default HomeRoute;
