import { createContext, useState } from "react";

export const AuthContext = createContext({
  IsloggedIn: false,
  setLoggedIn: () => {},
});

export const AuthProvider = ({ children }) => {
  const [IsloggedIn, setLoggedIn] = useState(false);

  const value = { IsloggedIn, setLoggedIn };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
