import { findSubPromotors } from "../sub-promotors";

export const countsSorting = (dataArray) => {
  const subPromotors = findSubPromotors(dataArray);

  const zeroCountEntries = [];
  const nonZeroCountEntries = [];

  dataArray.forEach((entry) => {
    if (entry[1].totalCount === 0 && !entry[1].adminPromotorId) {
      const entriesSubs = subPromotors.filter(
        (tallyRecord) =>
          tallyRecord[1].adminPromotorId === entry[1].promotor?.id
      );
      zeroCountEntries.push(entry, ...entriesSubs);
    } else if (entry[1].totalCount !== 0 && !entry[1].adminPromotorId) {
      const entriesSubs = subPromotors.filter(
        (tallyRecord) =>
          tallyRecord[1].adminPromotorId === entry[1].promotor?.id
      );
      nonZeroCountEntries.push(entry, ...entriesSubs);
    }
  });
  const sortedArray = nonZeroCountEntries.concat(zeroCountEntries);
  return sortedArray;
};
