export const findSubPromotorAndUpdateCount = (promotorTally, dataArray) => {
  const subPromotor = dataArray.filter((tallyRecord) => {
    if (tallyRecord[1].adminPromotorId && promotorTally.promotor) {
      return tallyRecord[1].adminPromotorId === promotorTally.promotor.id;
    }
  });

  if (subPromotor.length > 0) {
    subPromotor.forEach((sub) => {
      if (promotorTally.promotor.userType === "TEAM") {
        promotorTally.totalPeopleEntered += sub[1].totalPeopleEntered;
        promotorTally.totalComps += sub[1].totalComps;
        promotorTally.totalReduce += sub[1].totalReduce;
      }
    });
  }
};

// export const findPromotorAndUpdateCount = (subPromotorTally, dataArray) => {
//   const promotor = dataArray.find((tallyRecord) => {
//     if (tallyRecord[1].promotor) {
//       return tallyRecord[1].promotor.id === subPromotorTally.adminPromotorId;
//     }
//   });

//   if (!promotor === undefined) {
//     promotor[1].totalPeopleEntered += subPromotorTally.totalPeopleEntered;
//   }
// };
