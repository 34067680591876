import "./button.styles.js";
import { CloseButton, EventCardButton } from "./button.styles.js";

export const BUTTON_TYPE_CLASSES = {
  eventCard: "event-card",
  close: "close-btn",
};

const getButton = (buttonType) => {
  let selectedButton;

  switch (buttonType) {
    case BUTTON_TYPE_CLASSES.eventCard:
      selectedButton = EventCardButton;
      break;
    case BUTTON_TYPE_CLASSES.close:
      selectedButton = CloseButton;
      break;
    default:
      //   selectedButton = EventCardButton;
      break;
  }

  return selectedButton;
};

const ButtonComponent = ({ children, buttonType, ...otherProps }) => {
  const CustomButton = getButton(buttonType);
  return <CustomButton {...otherProps}> {children} </CustomButton>;
};

export default ButtonComponent;
