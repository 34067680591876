export const walkupSorting = (dataArray) => {
  // walkup sorting (in order to show it on the top of the list) (do not effect resus)
  dataArray.sort((firstElement, secondElement) => {
    if (firstElement[0] === "WALK UP") {
      // Place the "WALK UP" row at the beginning
      return -1;
    } else if (secondElement[0] === "WALK UP") {
      // Place the "WALK UP" row at the beginning
      return 1;
    } else {
      return 0;
    }
  });
  return dataArray;
};
