import "./loading-spinner.style.scss";
import { ScaleLoader } from "react-spinners";

const LoadingSpinner = () => {
  return (
    <div className="spinner-container">
      <ScaleLoader color="#4b0082" />
    </div>
  );
};

export default LoadingSpinner;
