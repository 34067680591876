import axios from "axios";
import { BASE_URL } from "../utils/constants/constants";

export const fetchGuestListReports = async (token, eventId) => {
  try {
    const params = {
      eventId: eventId,
    };

    const response = await axios.get(`${BASE_URL}/invitedVia/getInvites/all`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Platform: "web",
        Version: "5.6.1",
      },
      params: params,
    });
    const data = await response.data;
    return data;
  } catch (error) {
    throw error;
  }
};
