import { useEffect, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import AuthPage from "./pages/auth/auth-page";
import EventDetailsPage from "./routes/event-reports/event-reports-route";
import Navigation from "./components/navigation/navigation-bar.component";
import HomeRoute from "./routes/landing/landing-route";

const App = () => {
  const isLoggedIn = localStorage.getItem("loggedIn");

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);

  return (
    <Routes>
      <Route path="/" element={isLoggedIn ? <Navigation /> : <AuthPage />}>
        <Route index element={isLoggedIn ? <HomeRoute /> : <AuthPage />} />
        <Route path="auth" element={<AuthPage />} />
        <Route path="home/*" element={<HomeRoute />} />
        <Route path="event-details" element={<EventDetailsPage />} />
      </Route>
    </Routes>
  );
};

export default App;
