import "./form-input.style.scss";

const FormInput = ({ value, label, ...otherProps }) => {
  return (
    <div className="form-input-container">
      {label && <label className="form-input-label">{label}</label>}
      <input className="form-input" {...otherProps}></input>
    </div>
  );
};

export default FormInput;
