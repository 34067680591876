import { getRoleDisplayName } from "../user/get-role";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // for 12-hour format. If you want 24-hour format, set this to false or remove this line.
  };
  return date.toLocaleDateString(undefined, options);
};

export const seperateFieldsForGuestListReports = (originalArray) => {
  const modifiedArray = originalArray.map((item) => ({
    Event: item.event.eventName,
    Name: item.isExpressEntry ? capitalizeNames(item.fullName) : "",

    Email: item.isExpressEntry ? item.email : "",
    InvitedBy: capitalizeNames(item.member.fullName),
    Role: getRoleDisplayName(item.member.userType),
    Type: item.type,
    Code: item.code,
    Status: item.hasOwnProperty("isVerified") ? "Pending" : "Verified",
    Date: formatDate(item.createdAt),
    "Ticket Type": item.isExpressEntry ? "Express Entry" : "Walk-In",
  }));
  return modifiedArray;
};

export const capitalizeNames = (fullName) => {
  const names = fullName.split(" ");
  const capitalizedNames = names.map(
    (name) => name.charAt(0).toUpperCase() + name.slice(1)
  );
  return capitalizedNames.join(" ");
};
