import axios from "axios";
import { BASE_URL } from "../utils/constants/constants";

export const fetchEvents = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/events/past`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Platform: "web",
        Version: "5.6.1",
      },
    });
    const data = await response.data;
    return data;
  } catch (error) {
    throw error.response.data;
  }
};
