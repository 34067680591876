import { useContext, useEffect, useState } from "react";
import { fetchEvents } from "../../apis/event-api";
import EventCard from "../../components/event-card/event-card.component";
import { EventsContext } from "../../context/events-context";

import "./home-page.style.scss";

const HomePage = () => {
  const { events, setEvents } = useContext(EventsContext);
  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const getFinalEvents = async () => {
      try {
        const response = await fetchEvents(token);

        if (response.payLoad !== events) {
          setEvents(response.payLoad);
        }
      } catch (error) {
        console.error(error.message);

        if (error.statusCode === 401) {
          setErrorMessage(error.message);

          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/auth";
          }, 2000);
        }
      }
    };
    getFinalEvents();
  }, [token]);

  if (errorMessage) {
    return (
      <div className="all-events-container">
        <p className="home-error-message">
          Your Session has been expired Please Login again.
        </p>
      </div>
    );
  }

  return (
    <div className="all-events-container">
      {events.map((event) => {
        return <EventCard key={event.id} event={event} />;
      })}
    </div>
  );
};

export default HomePage;
