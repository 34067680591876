import { createContext, useState } from "react";

export const EventsContext = createContext({
  events: [],
  setEvents: () => {},
});

export const EventsProvider = ({ children }) => {
  const [events, setEvents] = useState([]);

  const value = { events, setEvents };

  return (
    <EventsContext.Provider value={value}> {children}</EventsContext.Provider>
  );
};
