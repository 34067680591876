import { useState } from "react";
import { useNavigate } from "react-router-dom";

import ButtonComponent from "../button/button.component";
import { BUTTON_TYPE_CLASSES } from "../button/button.component";

import "./event-card.style.scss";

const EventCard = ({ event }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { imageUrl, description, eventName } = event;
  const navigate = useNavigate();

  const onClickHandler = () => {
    sessionStorage.setItem("eventObject", JSON.stringify(event));
    navigate("event-details");
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const displayDescription = isExpanded
    ? description
    : `${description.substring(0, 200)}`;

  return (
    <div className="card-container">
      <img src={imageUrl} alt="event" className="event-img" />
      <h1 className="title">{eventName}</h1>
      <p className={`paragraph ${isExpanded ? "expanded" : ""}`}>
        {displayDescription}
        {description.length > 200 && (
          <span className="read-more" onClick={toggleExpand}>
            {isExpanded ? "Read Less" : "Read More"}
          </span>
        )}
      </p>
      <div className="button-box">
        <ButtonComponent
          type="button"
          buttonType={BUTTON_TYPE_CLASSES.eventCard}
          onClick={onClickHandler}
        >
          Event Report
        </ButtonComponent>
      </div>
    </div>
  );
};
export default EventCard;
