import { USER_AND_ENTRY_TYPES } from "../user/user-types";

export const selectClassName = (userType) => {
  let className;

  switch (userType) {
    case USER_AND_ENTRY_TYPES["WALK UP"]:
    case USER_AND_ENTRY_TYPES.TOTAL:
    case USER_AND_ENTRY_TYPES.PROMOTOR: {
      className = "promotor";
      break;
    }
    case USER_AND_ENTRY_TYPES.SUB_PROMOTOR:
    case "PROMOTOR_TEAM": {
      className = "sub-prom";
      break;
    }
    case "TEAM": {
      className = "team";
      break;
    }
    default: {
      className = "staff";
      break;
    }
  }
  return className;
};
// const className = tally.adminPromotorId
//   ? "sub-prom"
//   : tally.promotor
//   ? tally.promotor.userType
//   : tally.userType !== USER_TYPES.PROMOTOR
//   ? "staff"
//   : "promotor";
