import { USER_AND_ENTRY_TYPES } from "../../user/user-types";

export const setMainTallyCount = (
  reportsTallyObject,
  reportMap,
  resetTallysCountFn,
  calculateTotalTallysCountFn,
  updatedReturningObjectFn
) => {
  reportsTallyObject.map((tallyObject) => {
    const fullName = tallyObject.promotor.fullName;

    if (!reportMap.has(fullName)) {
      resetTallysCountFn(true, {});

      reportMap.set(
        fullName,
        updatedReturningObjectFn(
          tallyObject,
          calculateTotalTallysCountFn(tallyObject)
        )
      );
    } else {
      const existingTally = reportMap.get(fullName);

      resetTallysCountFn(false, existingTally);

      const tallyCounts = calculateTotalTallysCountFn(tallyObject);

      reportMap.set(
        fullName,
        updatedReturningObjectFn(tallyObject, tallyCounts)
      );
    }
  });
};

export const calculateMainTallyTotalCount = (
  reportsData,
  mainReportMap,
  calculateTotalTallysCountFn,
  totalMainTallysCount
) => {
  reportsData.map((tallyObject) => {
    if (tallyObject.promotor.fullName !== USER_AND_ENTRY_TYPES["WALK UP"]) {
      totalMainTallysCount = calculateTotalTallysCountFn(tallyObject);
      mainReportMap.set("Total", {
        ...totalMainTallysCount,
        userType: USER_AND_ENTRY_TYPES.TOTAL,
      });
    }
  });
};
