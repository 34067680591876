import styled from "styled-components";

export const EventCardButton = styled.button`
  cursor: pointer;
  width: 100%;
  border-radius: 30px;
  padding: 0.8rem 1.5rem;
  font-weight: bold;
  color: white;
  border: none;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: #ffffff44;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  position: fixed;
  top: 5px;
  right: 45px;
  font-size: 1rem;
  background: #4b0082;
  color: white;
  height: 2rem;
  width: 7.5rem;
  border-radius: 15px;
`;
