import SignInForm from "../../components/sign-in-form/sign-in-form.component";
import "./auth-page.style.scss";

const AuthPage = () => {
  return (
    <div className="auth-page">
      <SignInForm />
    </div>
  );
};
export default AuthPage;
